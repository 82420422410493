import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from '../hooks';
import { auth } from '../config/firebase';

const HeaderWrapper = styled.header`
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: saturate(180%) blur(20px);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
`;

const Nav = styled.nav`
  max-width: 980px;
  margin: 0 auto;
  padding: 0 22px;
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NavList = styled.ul`
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
  align-items: center;
`;

const NavItem = styled.li`
  margin: 0 10px;
  display: flex;
  align-items: center;
`;

const NavLink = styled(Link)`
  color: #f5f5f7;
  text-decoration: none;
  font-size: 14px;
  opacity: 0.8;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }
`;

const NavButton = styled.button`
  color: #f5f5f7;
  text-decoration: none;
  font-size: 14px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
`;

const ActionButton = styled(NavButton)`
  background-color: #0071e3;
  padding: 4px 11px;
  border-radius: 980px;
  opacity: 1;

  &:hover {
    background-color: #0077ed;
  }
`;

const DashboardButton = styled(ActionButton)`
  background-color: #34c759;

  &:hover {
    background-color: #30b651;
  }
`;

function Header() {
  const {user} = useAuth();
  const navigate = useNavigate();

  const handleSignOut = async () => {
    await auth.signOut();
    navigate('/');
  };

  return (
    <HeaderWrapper>
      <Nav>
        <NavList>
          <NavItem><NavLink to="/">Agile</NavLink></NavItem>
        </NavList>
        <NavList>
          <NavItem><NavLink to="/privacy">Privacy Policy</NavLink></NavItem>
          <NavItem><NavLink to="/terms">Terms of Service</NavLink></NavItem>
          {user && (
            <NavItem>
              <Link to="/dashboard" style={{ textDecoration: 'none' }}>
                <DashboardButton as="span">Dashboard</DashboardButton>
              </Link>
            </NavItem>
          )}
          {!user && (
            <NavItem>
              <Link to="/signin" style={{ textDecoration: 'none' }}>
                <ActionButton as="span">Sign In</ActionButton>
              </Link>
            </NavItem>
          )}
          {user && (
            <NavItem>
              <ActionButton onClick={handleSignOut}>Sign Out</ActionButton>
            </NavItem>
          )}
        </NavList>
      </Nav>
    </HeaderWrapper>
  );
}

export default Header;